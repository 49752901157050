
img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}

.up-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 80px;
    height: 32px;
    opacity: 0;
}

.up-img-show {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #eeeeee;
    vertical-align: middle;
    margin-left: 10px;
}
::v-deep.el-table--border::after, ::v-deep .el-table--group::after,  ::v-deep.el-table::before {
    background-color: transparent;
}
::v-deep.el-table--medium td, ::v-deep.el-table--medium th{
    padding: 12px 9px;
}

